@tailwind base;
@tailwind components;
@tailwind utilities;

.root,
#root,
#docs-root {
  --primary: #111827;
  --secondary: #1b263e;
  --one: #10b981;
  --two: #ffd84d;
  --three: #ff4255;
}
